.TilesIndicated {
  header {
    max-width: calc(584rem / 16);
    margin: 0 auto;
    text-align: center;
    .arrows {
      display: grid;
      grid-template-columns: auto auto;
      align-items: flex-end;
      justify-content: center;
      gap: 10vw;
      margin-bottom: calc(clamp(8px, calc(12vw / 7.68), 24px) * -1);
      z-index: 2;
      position: relative;
      pointer-events: none;
      .arrow1 {
        width: clamp(117px, calc(214vw / 7.68), 269px);
        height: auto;
      }
      .arrow2 {
        width: clamp(100px, calc(154vw / 7.68), 192px);
        height: auto;
      }
    }
  }
  .wrapper {
    display: grid;
    gap: clamp(12px, calc(16vw / 7.68), 32px);
    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }
    .item {
      padding: clamp(12px, calc(24vw / 7.68), 24px) clamp(12px, calc(24vw / 7.68), 24px)
        clamp(16px, calc(28vw / 7.68), 28px);
      border-radius: 4px;
      background-color: var(--primary-200);
      h3 {
        font-size: clamp(calc(18rem / 16), calc(18vw / 7.68), calc(24rem / 16));
      }
      .paragraph {
        margin: clamp(16px, calc(20vw / 7.68), 20px) 0 clamp(32px, calc(36vw / 7.68), 36px);
      }

      &[data-image='true'] {
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: auto 1fr;
        gap: 0 24px;

        img {
          width: 164px;
          aspect-ratio: 1/1;
          grid-column: 1/2;
          grid-row: 1/3;
          object-fit: cover;
          border-radius: 4px;
        }
        h3 {
          grid-column: 2/3;
          grid-row: 1/2;
          font-size: clamp(calc(18rem / 16), calc(24vw / 7.68), calc(24rem / 16));
        }

        .paragraph {
          grid-column: 2/3;
          grid-row: 2/3;
          margin-bottom: 0;
          margin-top: clamp(8px, calc(12vw / 7.68), 12px);
        }

        a {
          grid-column: 1/3;
          grid-row: 3/4;
          margin-top: clamp(24px, calc(36vw / 7.68), 48px);
        }
      }
    }

    &[data-image='true'] {
      @media (max-width: 1023px) {
        grid-template-columns: 1fr;

        .item {
          max-width: 824px;
          margin: 0 auto;
        }
      }

      @media (max-width: 799px) {
        .item {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, auto);

          img {
            grid-row: 1/2;
            grid-column: 1/2;
            margin-bottom: 24px;
          }

          h3 {
            grid-row: 2/3;
            grid-column: 1/2;
          }

          .paragraph {
            grid-row: 3/4;
            grid-column: 1/2;
          }

          a {
            grid-row: 4/5;
            grid-column: 1/2;
          }
        }
      }
    }
  }
}
